import { ActionContext } from "vuex"
import { AxiosResponse } from "axios"
import axiosApi from "@/services/axiosApi"
import { FacilitiesState } from "@/interfaces/FacilitesInterface"
import { FacilityData } from "@/types/Facilities"

export const facilities = {
    state: (): FacilitiesState => ({
        facilities: [],
        statusCode: null
    }),
    getters: {
        GET_FACILITIES: (state: FacilitiesState): FacilityData[] => {
            return state.facilities;
        },
        GET_FACILITY_STATUS_CODE: (state: FacilitiesState): FacilitiesState['statusCode'] => {
            return state.statusCode;
        }
    },
    mutations: {
        SET_FACILITIES: (state: FacilitiesState, data: FacilityData[]): void => {
            state.facilities = data;
        },
        SET_FACILITY_STATUS_CODE:(state: FacilitiesState, status: number)=>{
            state.statusCode = status
        }
    },
    actions: {
        async getFacilities({ commit }: ActionContext<FacilitiesState, any>, companyId: string): Promise<void> {
            try {
                const response: AxiosResponse<FacilityData[]> = await axiosApi.get(`/admin/facilities/${companyId}`);
                commit('SET_FACILITIES', response.data)
            } catch (e) {
                console.error('Error fetching facilities data:', e);
            }
        },
        async updateFacilities({ commit }: ActionContext<FacilitiesState, any>, {companyBranch, facility_ids, descriptions}: {companyBranch: string, facility_ids: string[], descriptions: string[] | null}): Promise<void> {
            try {
                const response: AxiosResponse<FacilityData[]> = await axiosApi.put(`/admin/facilities/${companyBranch}?_method=PUT`, {facility_ids, companyBranch, descriptions});
                commit('SET_FACILITIES', response.data)
                commit('SET_FACILITY_STATUS_CODE', response.status)
            } catch (e) {
                console.error('Error fetching facilities data:', e);
            }
        }
    }
}
