import { getAccessToken, removeAuthToken } from '@/services/authUser'
import axios, { AxiosResponse } from 'axios'
import { toast } from "vue3-toastify"
import { isSet } from "lodash"

const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

const setDefaultHeaders = () => {
    axiosApi.defaults.headers.common['Content-Type'] = 'application/json';
    axiosApi.defaults.headers.common['Accept'] = 'application/json';
}

export const setAuthHeader = (token: string) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const apiAuthenticate = () => {
    const token = getAccessToken();
    if (token) {
        setAuthHeader(token);
    }
}

setDefaultHeaders()
apiAuthenticate()

axiosApi.interceptors.response.use(
    (response: AxiosResponse<any, any> | undefined): any | undefined => {
        if (response) {
            if (response.data.message) {
                toast.success(response.data.message, {
                    theme: 'colored',
                    autoClose: 3000,
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            return response;
        }
    },
    (error) => {
        if (!isSet(error.response?.status)) {
            switch (error.response.status) {
                case 401:
                case 403:
                    removeAuthToken()
                    break;
                case 422:
                    toast.error(error.response.data.message
                        ? Object.values(error.response.data.details).join('\n')
                        : error.response.data.message, {
                        autoClose: 15000,
                        theme: 'colored',
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    return error;
                    break;
                default:
                    toast.error(error.response.data.errors
                        ? Object.values(error.response.data.errors).join('\n')
                        : 'An unexpected error occurred', {
                        autoClose: 15000,
                        theme: 'colored',
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
            }
        } else {
            toast.error('An unexpected error occurred', {
                autoClose: 15000,
                theme: 'colored',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }

});

export default axiosApi;
