import { ActionContext } from 'vuex'
import axiosApi from "@/services/axiosApi"
import {CategoriesState, Category, CategoryDetails} from "@/interfaces/CategoryInterface"
import router from "@/router";
import {AxiosResponse} from "axios";

export const categories = {
    namespaced: true,

    // State
    state: (): CategoriesState => ({
        categories: [],
        category: []
    }),

    // Getters
    getters: {
        GET_CATEGORIES(state: CategoriesState): Category[] {
            return state.categories;
        },
        GET_CATEGORY(state: CategoriesState): CategoryDetails[] {
            return state.category;
        },
        GET_CATEGORIES_AND_SUBCATEGORIES(state: CategoriesState): Category[] {
            return state.categories;
        }
    },

    // Mutations
    mutations: {
        SET_CATEGORIES(state: CategoriesState, categories: Category[]): void {
            state.categories = categories;
        },
        SET_CATEGORY(state: CategoriesState, category: CategoryDetails[]): void {
            state.category = category;
        },
        SET_CATEGORIES_AND_SUBCATEGORIES(state: CategoriesState, categories: Category[]): void {
            state.categories = categories;
        }
    },

    // Actions
    actions: {
        async fetchCategories({ commit }: ActionContext<CategoriesState, any>, { id, lang }: {id: string, lang: string}): Promise<void> {
            axiosApi.defaults.headers.common['Accept-Language'] = lang;
           try {
               const response = await axiosApi.get(`admin/categories/${id}`);
               commit('SET_CATEGORIES', response.data.data);
           }catch (e: any) {
               console.error('Error fetching categories:', e.messages)
           }
        },
        async fetchCategoriesAndSubcategories({ commit }: ActionContext<CategoriesState, any>, { id, lang }: {id: string, lang: string}): Promise<void> {
            axiosApi.defaults.headers.common['Accept-Language'] = lang;
           try {
               const response: AxiosResponse<Record<any, any>> = await axiosApi.get(`admin/categories/${id}/subcategories`);
               commit('SET_CATEGORIES_AND_SUBCATEGORIES', response.data.data);
           }catch (e: any) {
               console.error('Error fetching categories:', e.messages)
           }
        },
        async createCategory({ commit }: ActionContext<CategoriesState, any>, formData: any): Promise<void> {
           try {
               return await axiosApi.post(`admin/categories/${router.currentRoute.value.params.id}/store`, formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data',
                   },
               })
           }catch (e: any) {
               console.error('Error fetching categories:', e.messages)
           }
        },
        async getCategoryDetails({ commit }: ActionContext<CategoriesState, any>): Promise<void> {
           try {
               const response: AxiosResponse<Record<any, any>> = await axiosApi.get(`admin/categories/${router.currentRoute.value.params.category_id}/details`)
               commit('SET_CATEGORY', response.data.data)
           }catch (e: any) {
               console.error('Error fetching categories:', e.messages)
           }
        },
        async updateCategory({commit}: ActionContext<CategoriesState, any>, formData: any): Promise<void> {
           try {
               return await axiosApi.post(`/admin/categories/${router.currentRoute.value.params.category_id}?_method=PUT`, formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data',
                   },
               })
           }catch (e: any) {
               console.error('Error fetching categories:', e.messages)
           }
        },
        async deleteCategory({ commit }: ActionContext<CategoriesState, any>, id: string): Promise<void> {
           try {
               return await axiosApi.delete(`admin/categories/${id}`)
           }catch (e: any) {
               console.error('Error delete category:', e.messages)
           }
        }
    }
};
