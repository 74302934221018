import { Commit } from 'vuex';
import {AxiosResponse} from "axios";
import axiosApi from "@/services/axiosApi";

interface ReviewsState {
    reviewsData: any[] | null,
    companyUsers: any[] | null
}

export const reviewsStore = {
    namespaced: true,
    state: (): ReviewsState => ({
        reviewsData: null,
        companyUsers: [],
    }),
    getters: {
        GET_COMPANY_USERS: (state: ReviewsState): any[] | null => {
            return state.companyUsers
        }
    },
    mutations: {
        setReviews(state: ReviewsState, reviews: any[]) {
            state.reviewsData = reviews;
        },
        setCompanyUsers(state: ReviewsState, companyUsers: any[]) {
            state.companyUsers = companyUsers;
        }
    },
    actions: {
        async fetchData({ commit }: { commit: Commit }, filters: any){
            try {
                const response: AxiosResponse<object> = await axiosApi.post(`/admin/reviews`, null, { params: filters });
                commit('setReviews', response.data);
            } catch (e) {
                console.log(e)
            }
        },
        async fetchCompanyUsers({ commit }: { commit:Commit }, companyBranchId: any){
            const response: AxiosResponse<object> = await axiosApi.get(`/admin/users/${companyBranchId}`);
            commit('setCompanyUsers', response.data);
            return response
        },
    }
};
