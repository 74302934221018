import en from "@/i18n/lang/en.json";
import hy from "@/i18n/lang/am.json";
import ru from "@/i18n/lang/ru.json";
import { MessagesType } from "@/types/locales";
import {i18n} from "@/i18n/index";

export enum Locales {
  EN = 'English',
  HY = 'Armenian',
  RU = 'Russian',
}

export const messages: Record<Locales, MessagesType> = {
  [Locales.EN]: en,
  [Locales.HY]: hy,
  [Locales.RU]: ru
};

export const languageKey = (value: string): keyof typeof Locales | undefined => {
  return Object.keys(Locales).find(key => Locales[key as keyof typeof Locales] === value) as keyof typeof Locales
}

export const currentLanguage = () => languageKey(i18n.global.locale.value || Locales.EN) as string