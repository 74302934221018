import { useStore } from 'vuex'
import router from '@/router'
import {addAuthToken, getAccessToken, removeTokenFromStorage} from '@/services/authUser'
import { setAuthHeader } from '@/services/axiosApi'
import { initPusher } from '@/services/pusher'

export default function useAuthenticate (): {
    logout: () => Promise<void>;
    authenticate: (token: (string | null)) => Promise<void>;
    login: (data: Auth) => Promise<void>
    } {
  const store = useStore()

  const login = async (data: Auth) => {
    addAuthToken(data)
    setAuthHeader(data.auth.access_token)
    await authenticate(data.auth.access_token)
    await initPusher(
      data.auth.access_token,
      store.state.adminUser.user?.company_branch_id
    )
    router.go(0)
  }

  const logout = async () => {
    removeTokenFromStorage()
    await store.dispatch('adminUser/logout')
    store.commit('adminUser/resetToken')
    store.commit('adminUser/resetUser')
    router.go(0)
  }

  const authenticate = async (token: string|null) => {
    const accessToken = token || getAccessToken()
    if (accessToken) {
      await store.dispatch('adminUser/getUser')
      store.commit('adminUser/setToken', accessToken)
    }
  }

  return {
    login,
    logout,
    authenticate
  }
}
