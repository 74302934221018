import {LanguageData, LanguageState} from "@/interfaces/LanguageInterface";
import {Commit} from "vuex";
import axiosApi from "@/services/axiosApi"

export const languages = {
    namespaced: true,
    state: (): LanguageState => ({
        languages: [] as Array<LanguageData>
    }),
    getters: {
        GET_LANGUAGES: (state: LanguageState) => state.languages,
        GET_ALL_LANGUAGES: (state: LanguageState) => state.languages,
        GET_LANGUAGES_CODES: (state: LanguageState) => state.languages.map((language: LanguageData) => language.code)
    },
    mutations: {
        SET_LANGUAGES: (state: LanguageState, languages: Array<LanguageData>) => {
            state.languages = languages
        },
        SET_ALL_LANGUAGES: (state: LanguageState, languages: Array<LanguageData>) => {
            state.languages = languages
        }
    },
    actions: {
        async fetchLanguages({ commit }: { commit: Commit }): Promise<void> {
            await axiosApi
                .get<LanguageData[]>('/available-languages')
                .then((response: { data: any }): void => {
                    commit('SET_LANGUAGES', response.data.data)
                }).catch(e => {
                    console.error('error', e.messages)
            })
        },
        async fetchAllLanguages({ commit }: { commit: Commit }): Promise<void> {
            await axiosApi
                .get<LanguageData[]>('/languages')
                .then((response: { data: any }): void => {
                    commit('SET_ALL_LANGUAGES', response.data.data)
                }).catch(e => {
                    console.error('error', e.messages)
                })
        }
    }
};