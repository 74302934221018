import { createStore } from 'vuex'
import { adminUser } from './adminUser'
import { chat } from './chat'
import { chatMessages } from '@/store/chatMessages'
import { chatTabs } from '@/store/chatTabs'
import { reviewsStore } from '@/store/reviews'
import { chatStatistics } from '@/store/chatStatistics'
import { notifications } from "@/store/notifications"
import { orders } from "@/store/orders"
import { categories } from "@/store/categories";
import { companies } from "@/store/companies"
import { facilities } from "@/store/facilities"
import { languages } from "@/store/languages"
import { services } from "@/store/services"
import { users } from "@/store/users"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    adminUser,
    chat,
    chatMessages,
    chatTabs,
    chatStatistics,
    reviewsStore,
    notifications,
    orders,
    companies,
    facilities,
    categories,
    languages,
    services,
    users
  }
})
