import AxiosApi from "@/services/axiosApi"
import {AxiosResponse} from "axios";

export const users = {
    namespaced: true,
    state: () => ({
        isLoading: false,
    }),
    getters: {
        GET_STATUS_CODE:(state: any): any => {
            return state.statusCode
        }
    },
    mutations: {
        SET_STATUS_CODE: (state: any, status: number|null|undefined): void => {
            state.statusCode = status
        }
    },
    actions: {
        async createUser({ commit }: any, { formData, companyBranchId }: { formData: any, companyBranchId: string }): Promise<void> {
            await AxiosApi.post(`/admin/users/${companyBranchId}/store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                commit('SET_STATUS_CODE', response?.status)
            })
        },
        async getUser({ commit }: any, { userId }: { userId: string }): Promise<void> {
           return await AxiosApi.get(`/admin/users/${userId}/details`)
        },
        async updateUser({ commit }: any, { formData, userId }: { formData: any, userId: string }): Promise<void> {
            await AxiosApi.post(`admin/users/${userId}?_method=PUT`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                commit('SET_STATUS_CODE', response?.status)
            })
        },
        async deleteUser({ commit }: any, id: string): Promise<void> {
            try {
                await AxiosApi.delete(`/admin/users/${id}`).then((response: AxiosResponse<any, any>): void => {
                    commit('SET_STATUS_CODE', response?.status)
                })
            }catch (error) {
                console.error('Error deleting user:', error)
            }
        }
    }
}