import axiosApi from "@/services/axiosApi";
import router from "@/router";
import {ActionContext} from "vuex";
import {CategoriesState} from "@/interfaces/CategoryInterface";

export const services = {
    namespaced: true,
    state: () => ({}),
    getters: {},
    mutations: {},
    actions: {
        async createService({ commit }: ActionContext<CategoriesState, any>, formData: any): Promise<void> {
            try {
                return await axiosApi.post(`admin/services/${router.currentRoute.value.params.id}/store`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            } catch (e: any) {
                console.error('Error fetching categories:', e.messages)
            }
        },
        async updateService({ commit }: ActionContext<CategoriesState, any>, formData: any): Promise<void> {
            try {
                return await axiosApi.post(`admin/services/${router.currentRoute.value.params.service_id}?_method=PUT`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            } catch (e: any) {
                console.error('Error fetching categories:', e.messages)
            }
        },
        async deleteService({ commit }: ActionContext<CategoriesState, any>, id: string): Promise<void> {
            try {
                return await axiosApi.delete(`admin/services/${id}`)
            }catch (e: any) {
                console.error('Error delete category:', e.messages)
            }
        }
    }
}